import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map } from 'rxjs';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  environnement: any = environment;
  constructor(private http: HttpClient) {}

  public user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isLoadingUser: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  login(login: string, password: string) {
    return this.http.post(`${API_URL}/api/auth/login`, {
      login,
      password,
    });
  }

  refreshToken(token: string) {
    return this.http.post(`${API_URL}/api/auth/refreshToken`, {
      token,
    });
  }

  logout() {
    this.user.next(null);
    localStorage.removeItem('atelier_token');
    localStorage.removeItem('atelier_refresh');
  }

  me(token: string) {
    return this.http.post(`${API_URL}/api/auth/me`, {
      token,
    });
  }

  isLoggedIn() {
    return this.user.pipe(
      map((user) => {
        return !!user;
      })
    );
  }

  isLoggedOut() {
    return this.isLoggedIn().pipe(
      map((isLoggedIn) => {
        return !isLoggedIn;
      })
    );
  }
}
