import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        // si l'utilisateur est connecté -> redirection accueil
        if (isLoggedIn) {
          this.router.navigateByUrl('/');
          return of(false);
        }

        const token = localStorage.getItem('atelier_token');
        // si il n'y a pas de token, on verifie si il y a le refresh token
        if (!token) {
          const refreshToken = localStorage.getItem('atelier_refresh');
          // si il y a pas le refresh token -> next
          if (!refreshToken) {
            return of(true);
          }
          // récuperation des infos utilisateur avec le refresh_token
          this.authService.isLoadingUser.next(true);
          return this.authService.refreshToken(refreshToken).pipe(
            map((response: any) => {
              this.authService.isLoadingUser.next(false);
              localStorage.setItem('atelier_token', response.data.token);
              this.authService.user.next(response.data.user);
              this.router.navigateByUrl('/');
              return false;
            }),
            // le refresh token est expiré -> next
            catchError((err) => {
              this.authService.isLoadingUser.next(false);
              this.authService.logout();
              return of(true);
            })
          );
        }

        // récuperation des infos utilisateur avec le token
        this.authService.isLoadingUser.next(true);
        return this.authService.me(token).pipe(
          map((response: any) => {
            this.authService.isLoadingUser.next(false);
            this.authService.user.next(response.data);
            this.router.navigateByUrl('/');
            return false;
          }),
          // le token est expiré, on verifie le refresh token
          catchError((err) => {
            const refreshToken = localStorage.getItem('atelier_refresh');
            // si il n'y a pas de refresh token -> next
            if (!refreshToken) {
              this.authService.isLoadingUser.next(false);
              this.authService.logout();
              return of(true);
            }

            // récuperation des infos utilisateur avec le refresh_token
            return this.authService.refreshToken(refreshToken).pipe(
              map((response: any) => {
                this.authService.isLoadingUser.next(false);
                localStorage.setItem('atelier_token', response.data.token);
                this.authService.user.next(response.data.user);
                this.router.navigateByUrl('/');
                return false;
              }),
              // le refresh token est expiré -> next
              catchError((err) => {
                this.authService.isLoadingUser.next(false);
                this.authService.logout();
                return of(true);
              })
            );
          })
        );
      })
    );
  }
}
