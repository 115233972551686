<nav class="border-gray-200 bg-white px-2 py-2.5 sm:px-4">
  <div class="container mx-auto flex flex-wrap items-center justify-between">
    <div class="flex items-center">
      <img src="/assets/logo.png" class="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
    </div>
    <div class="flex space-x-8">
      <div class="flex w-auto items-center justify-center" id="mobile-menu">
        <ul
          class="flex space-x-3 md:mt-0 md:flex-row md:text-sm md:font-medium"
        >
          <li>
            <a
              routerLink="/"
              class="block rounded-xl bg-green-700 py-2 pr-4 pl-3 text-white"
              aria-current="page"
              >Accueil</a
            >
          </li>
          <li>
            <div
              (click)="logout()"
              class="block cursor-pointer rounded-xl bg-red-700 py-2 pr-4 pl-3 text-white"
            >
              Déconnexion
            </div>
          </li>
        </ul>
      </div>
      <div class="flex items-center space-x-4">
        <div
          class="relative h-10 w-10 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600"
        >
          <svg
            class="absolute -left-1 h-12 w-12 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <div class="text-sm font-medium">
          <div>Admin</div>
          <div class="text-sm text-gray-500 dark:text-gray-400">
            {{ user.email }}
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
