import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/auth/login/login.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { ReadComponent } from './pages/read/read.component';
import { DeleteComponent } from './pages/delete/delete.component';
import { EditComponent } from './pages/edit/edit.component';
import { CreateComponent } from './pages/create/create.component';
import { DataComponent } from './pages/data/data.component';
import { RealTimeDataComponent } from './pages/real-time-data/real-time-data.component';
import { NoAuthGuard } from './guards/no-auth/no-auth.guard';

const routes: Routes = [
  { path: 'connexion', component: LoginComponent, canActivate: [NoAuthGuard] },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':numSerie/edit',
    component: EditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':numSerie/delete',
    component: DeleteComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':numSerie/read',
    component: ReadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create',
    component: CreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':numSerie/data',
    component: DataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':numSerie/realtimedata',
    component: RealTimeDataComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
