import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Unit } from 'src/app/interface/unit';

const API_URL = `${environment.apiUrl}/api/device/`;

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  environnement: any = environment;
  constructor(private http: HttpClient, private router: Router) {}

  getAllDevice(page: number) {
    return this.http.get(`${API_URL}`, { params: { page } });
  }

  getDeviceByNumSerie(numSerie: string) {
    return this.http.get(`${API_URL}${encodeURIComponent(numSerie)}`);
  }

  updateDeviceByNumSerie(numSerie: string, payload: any) {
    return this.http.patch(
      `${API_URL}${encodeURIComponent(numSerie)}`,
      payload
    );
  }

  addDevice(payload: any) {
    return this.http.post(`${API_URL}`, payload);
  }

  deleteDeviceByNumSerie(numSerie: string) {
    return this.http.delete(`${API_URL}${encodeURIComponent(numSerie)}`);
  }

  getDataByNumSerie(
    numSerie: string,
    granularity: string,
    dateStart: string,
    dateStop: string
  ) {
    return this.http.post(`${API_URL}${encodeURIComponent(numSerie)}/data`, {
      granularity,
      dateStart,
      dateStop,
    });
  }
}
