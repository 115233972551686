<body class="min-h-screen bg-gray-100">
  <app-navbar></app-navbar>
  <div class="container mx-auto pt-5">
    <button
      (click)="back()"
      class="rounded-full bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800"
    >
      Retour
    </button>
    <div class="mt-5 flex w-full flex-col items-center">
      <form [formGroup]="dataForm" #form="ngForm" class="w-full">
        <div class="mb-6 flex w-full flex-col">
          <div class="flex w-full gap-2">
            <div>
              <label
                for="dateStart"
                class="mb-2 block text-sm font-medium text-gray-900"
                >Date de début</label
              >
              <mat-form-field
                class="rounded-lg border border-gray-300 bg-white p-1 text-sm"
              >
                <input
                  matInput
                  [ngxMatDatetimePicker]="picker"
                  formControlName="dateStart"
                  (dateChange)="onDateChange()"
                  [readonly]="true"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="$any(picker)"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #picker
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [enableMeridian]="false"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>
              <div
                *ngIf="
                  dateStart!.invalid &&
                  (dateStart!.dirty || dateStart!.touched || form.submitted)
                "
                class="text-xs text-red-600"
              >
                <div *ngIf="dateStart!.errors?.['required']">
                  Veuillez saisir une date de début
                </div>
              </div>
            </div>

            <div>
              <label
                for="dateStop"
                class="mb-2 block text-sm font-medium text-gray-900"
                >Date de fin</label
              >
              <mat-form-field
                class="rounded-lg border border-gray-300 bg-white p-1 text-sm"
              >
                <input
                  matInput
                  [ngxMatDatetimePicker]="picker2"
                  formControlName="dateStop"
                  (dateChange)="onDateChange()"
                  [readonly]="true"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="$any(picker2)"
                ></mat-datepicker-toggle>
                <ngx-mat-datetime-picker
                  #picker2
                  [showSpinners]="true"
                  [showSeconds]="false"
                  [enableMeridian]="false"
                >
                </ngx-mat-datetime-picker>
              </mat-form-field>
              <div
                *ngIf="
                  dateStop!.invalid &&
                  (dateStop!.dirty || dateStop!.touched || form.submitted)
                "
                class="text-xs text-red-600"
              >
                <div *ngIf="dateStop!.errors?.['required']">
                  Veuillez saisir une date de fin
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="dateStart!.errors?.['dateRange']"
            class="text-xs text-red-600"
          >
            La date de début doit être inférieur à la date de fin
          </div>
        </div>

        <div class="mb-6">
          <div class="w-full">
            <label
              for="granularity"
              class="mb-2 block text-sm font-medium text-gray-900"
              >Données</label
            >
            <div class="mb-6 flex w-full flex-wrap gap-2">
              <button
                (click)="setGranularity('minute')"
                class="rounded-full {{
                  granularity === 'minute'
                    ? 'bg-green-700 text-white'
                    : 'bg-white text-black border border-gray-300'
                }}  px-5 py-2.5 text-center text-sm font-medium  "
              >
                Minute
              </button>
              <button
                (click)="setGranularity('hour')"
                class="rounded-full {{
                  granularity === 'hour'
                    ? 'bg-green-700 text-white'
                    : 'bg-white text-black border border-gray-300'
                }} px-5 py-2.5 text-center text-sm font-medium text-white "
              >
                Heure (moyenne)
              </button>
              <button
                (click)="setGranularity('day')"
                class="rounded-full {{
                  granularity === 'day'
                    ? 'bg-green-700 text-white'
                    : 'bg-white text-black border border-gray-300'
                }} px-5 py-2.5 text-center text-sm font-medium text-white "
              >
                Jour (moyenne)
              </button>
              <button
                (click)="setGranularity('month')"
                class="rounded-full {{
                  granularity === 'month'
                    ? 'bg-green-700 text-white'
                    : 'bg-white text-black border border-gray-300'
                }} px-5 py-2.5 text-center text-sm font-medium text-white "
              >
                Mois (moyenne)
              </button>
              <button
                (click)="setGranularity('year')"
                class="rounded-full {{
                  granularity === 'year'
                    ? 'bg-green-700 text-white'
                    : 'bg-white text-black border border-gray-300'
                }} px-5 py-2.5 text-center text-sm font-medium text-white "
              >
                Année (moyenne)
              </button>
            </div>
          </div>
        </div>
      </form>
      <ngx-charts-line-chart
        class="h-72"
        [scheme]="colorScheme"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [timeline]="true"
        [results]="data"
        [animations]="true"
        [legend]="true"
        [showGridLines]="true"
        [roundDomains]="true"
        legendTitle="Légende"
      >
      </ngx-charts-line-chart>
    </div>
  </div>
</body>
