import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { map, catchError, switchMap, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        //si l'utilisateur est connecté -> next
        if (isLoggedIn) {
          return of(true);
        }

        const token = localStorage.getItem('atelier_token');
        // si il n'y a pas de token, on verifie le refresh_token
        if (!token) {
          const refreshToken = localStorage.getItem('atelier_refresh');
          // si il n'y a pas de refresh token -> redirection connexion
          if (!refreshToken) {
            this.router.navigateByUrl('/connexion');
            return of(false);
          }

          // récuperation des infos utilisateur avec le refresh token
          this.authService.isLoadingUser.next(true);
          return this.authService.refreshToken(refreshToken).pipe(
            map((response: any) => {
              this.authService.isLoadingUser.next(false);
              localStorage.setItem('atelier_token', response.data.token);
              this.authService.user.next(response.data.user);
              return true;
            }),
            // le refresh token est expiré -> redirection connexion
            catchError((err) => {
              this.authService.isLoadingUser.next(false);
              this.authService.logout();
              this.router.navigateByUrl('/connexion', {
                state: { sessionExpire: true },
              });
              return of(false);
            })
          );
        }

        // récuperation des infos utilisateur avec le token
        this.authService.isLoadingUser.next(true);
        return this.authService.me(token).pipe(
          map((response: any) => {
            this.authService.isLoadingUser.next(false);
            this.authService.user.next(response.data);
            return true;
          }),
          // le token est expiré, on vérifie le refresh token
          catchError((err) => {
            const refreshToken = localStorage.getItem('atelier_refresh');

            // si il n'y a pas de refresh token -> redirection connexion
            if (!refreshToken) {
              this.authService.isLoadingUser.next(false);
              this.authService.logout();
              this.router.navigateByUrl('/connexion', {
                state: { sessionExpire: true },
              });
              return of(false);
            }

            // récuperation des infos utilisateur avec le refresh token
            return this.authService.refreshToken(refreshToken).pipe(
              map((response: any) => {
                this.authService.isLoadingUser.next(false);
                localStorage.setItem('atelier_token', response.data.token);
                this.authService.user.next(response.data.user);
                return true;
              }),
              // le refresh token est expiré -> redirection connexion
              catchError((err) => {
                this.authService.isLoadingUser.next(false);
                this.authService.logout();
                this.router.navigateByUrl('/connexion', {
                  state: { sessionExpire: true },
                });
                return of(false);
              })
            );
          })
        );
      })
    );
  }
}
