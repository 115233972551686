import { AuthService } from '../services/auth/auth.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}
  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // récuperation du token dans le localStorage
    const token = localStorage.getItem('atelier_token')!;
    // ajout du token dans le header
    if (token) {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      request = request.clone({ setHeaders: headers });
    }

    return next.handle(request).pipe(
      // interception des erreur 401
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          return this.handle401Error(request, next, error);
        }
        return throwError(() => new Error(error.message));
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    err: HttpErrorResponse
  ) {
    // si il n'y a pas de token -> redirection connexion
    const refresh_token = localStorage.getItem('atelier_refresh');
    if (!refresh_token) {
      this.authService.logout();
      this.router.navigateByUrl('/connexion', {
        state: { sessionExpire: true },
      });
      return throwError(() => new Error(err.message));
    }

    // si la tentative de récuperation d'un nouveau token n'est pas en cours, on lance le process
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // récuperation d'un nouveau token avec le refresh_token
      return this.authService.refreshToken(refresh_token).pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          localStorage.setItem('atelier_token', response.data.token);
          this.refreshTokenSubject.next(response.data.token);

          // on renvoie la requete initiale avec le nouveau token dans le header
          return next.handle(this.addTokenHeader(request, response.data.token));
        }),
        // si le refresh token est invalide -> redirection connexion
        catchError((err: HttpErrorResponse) => {
          this.isRefreshing = false;

          this.authService.logout();
          this.router.navigateByUrl('/connexion', {
            state: { sessionExpire: true },
          });
          return throwError(() => new Error(err.message));
        })
      );
    }

    // si la tentative de récuperation d'un nouveau token est en cours, on attend sa réponse
    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  // modification du header avec le nouveau token
  private addTokenHeader(request: HttpRequest<any>, token: string) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    return request.clone({ setHeaders: headers });
  }
}
