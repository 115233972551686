<body class="min-h-screen bg-gray-100">
  <app-navbar></app-navbar>
  <div class="container mx-auto pt-5">
    <button
      routerLink="/"
      class="rounded-full bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
    >
      Retour
    </button>
    <div class="mt-5 flex flex-col items-center gap-2 md:flex-row">
      <form
        [formGroup]="createForm"
        (ngSubmit)="onSubmit()"
        #form="ngForm"
        class="w-full grow"
      >
        <div class="mb-6">
          <label
            for="numSerie"
            class="mb-2 block text-sm font-medium text-gray-900"
            >N° de série</label
          >
          <input
            type="text"
            formControlName="numSerie"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
            placeholder="N° de série"
            required
          />
          <div
            *ngIf="
              numSerie!.invalid &&
              (numSerie!.dirty || numSerie!.touched || form.submitted)
            "
            class="text-xs text-red-600"
          >
            <div *ngIf="numSerie!.errors?.['required']">
              Veuillez saisir un numéro de série.
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="w-full rounded-lg bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 sm:w-auto"
        >
          Ajouter
        </button>
      </form>
      <div class="flex flex-col items-center text-center">
        <div #qrcode class="p-3">
          <qr-code
            *ngIf="numSerie!.value"
            #parent
            [value]="numSerie!.value"
            [size]="350"
            level="H"
          ></qr-code>
          <span>{{ numSerie!.value | numSerie }}</span>
        </div>
        <div class="flex gap-2">
          <button
            *ngIf="numSerie!.value"
            (click)="saveAsImage()"
            class="mt-2 rounded-lg bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 sm:w-auto"
          >
            Télecharger image
          </button>
          <button
            *ngIf="numSerie!.value"
            (click)="generatePDF()"
            class="mt-2 rounded-lg bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 sm:w-auto"
          >
            Télecharger PDF
          </button>
        </div>
      </div>
    </div>
  </div>
</body>
