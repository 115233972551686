import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function dateRangeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dateStart = control.get('dateStart')!;
    const dateStop = control.get('dateStop')!;

    if (
      dateStart?.value != null &&
      dateStop?.value != null &&
      dateStart?.value >= dateStop?.value
    ) {
      dateStart.setErrors({ dateRange: true });
    }
    return null;
  };
}
