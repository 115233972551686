<body class="min-h-screen bg-gray-100">
  <app-navbar></app-navbar>
  <div class="container mx-auto pt-5">
    <button
      (click)="back()"
      class="rounded-full bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
    >
      Retour
    </button>
    <div
      class="mt-5 flex w-full flex-col items-center justify-center gap-2 md:flex-row"
    >
      <form
        [formGroup]="editForm"
        (ngSubmit)="onSubmit()"
        #form="ngForm"
        class="w-full grow"
      >
        <div class="mb-6">
          <label
            for="localisation"
            class="mb-2 block text-sm font-medium text-gray-900"
            >Localisation</label
          >
          <input
            type="text"
            formControlName="localisation"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
          />
        </div>
        <div class="mb-6">
          <label
            for="capacite"
            class="mb-2 block text-sm font-medium text-gray-900"
            >Capacité</label
          >
          <input
            type="number"
            formControlName="capacite"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
          />
        </div>
        <div class="mb-6">
          <label
            for="impulsionParLitre"
            class="mb-2 block text-sm font-medium text-gray-900"
            >Impulsion par litre</label
          >
          <input
            type="number"
            formControlName="impulsionParLitre"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
          />
        </div>
        <div class="mb-6">
          <label
            for="puissance"
            class="mb-2 block text-sm font-medium text-gray-900"
            >Puissance</label
          >
          <input
            type="number"
            formControlName="puissance"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
          />
        </div>

        <div class="mb-6">
          <label
            for="modele"
            class="mb-2 block text-sm font-medium text-gray-900"
            >Modèle</label
          >
          <input
            type="number"
            formControlName="modele"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
          />
        </div>
        <button
          type="submit"
          class="w-full rounded-lg bg-yellow-400 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-blue-300 sm:w-auto"
        >
          Modifier
        </button>
      </form>
      <div class="flex flex-col items-center text-center" *ngIf="device">
        <div #qrcode class="p-3">
          <qr-code
            *ngIf="device!.numSerie"
            #parent
            [value]="device!.numSerie"
            [size]="350"
            level="H"
          ></qr-code>
          <span>{{ device!.numSerie | numSerie }}</span>
        </div>
        <div class="flex gap-2">
          <button
            *ngIf="device!.numSerie"
            (click)="saveAsImage()"
            class="mt-2 rounded-lg bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 sm:w-auto"
          >
            Télecharger image
          </button>
          <button
            *ngIf="device!.numSerie"
            (click)="generatePDF()"
            class="mt-2 rounded-lg bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 sm:w-auto"
          >
            Télecharger PDF
          </button>
        </div>
      </div>
    </div>
  </div>
</body>
