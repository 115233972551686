import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'outilEsoffo';
  isLoading: any;

  constructor(private authService: AuthService, private router: Router) {
    this.authService.isLoadingUser.subscribe({
      next: (isLoadingUser) => {
        this.isLoading = isLoadingUser;
      },
    });
  }
}
