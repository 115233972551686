import { PdfService } from './../../services/pdf/pdf.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, startWith, switchMap } from 'rxjs';
import { DeviceService } from 'src/app/services/device/device.service';
import moment from 'moment-timezone';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, AfterViewInit {
  devices: Array<any> = [];
  isLoading: boolean = true;
  searchForm!: FormGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  resultsLength = 0;

  errors: Array<any> = [];
  constructor(
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    private pdfService: PdfService
  ) {}

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group(
      {
        numSerie: ['', Validators.required],
      },
      {
        updateOn: 'submit',
      }
    );
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          this.devices = [];
          return this.deviceService
            .getAllDevice(this.paginator.pageIndex + 1)
            .pipe(catchError(() => observableOf(null)));
        }),
        map((response: any) => {
          this.isLoading = false;
          if (response.data == null) {
            return [];
          }
          this.resultsLength = response.data.total;
          return response.data.data;
        })
      )
      .subscribe({
        next: (data) => {
          this.devices = data;
        },
        error: () => {
          this.isLoading = false;
          this.errors.push('Impossible de récupérer les devices');
        },
      });
  }

  onSubmit() {
    if (this.searchForm.valid) {
      this.paginator.pageIndex = 0;
      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoading = true;
            this.devices = [];
            return this.deviceService
              .getDeviceByNumSerie(this.searchForm.get('numSerie')!.value)
              .pipe(catchError(() => observableOf(null)));
          }),
          map((response: any) => {
            console.log(response);

            this.isLoading = false;
            if (response.data == null) {
              this.resultsLength = 0;
              return [];
            }

            this.resultsLength = 1;
            return [response.data];
          })
        )
        .subscribe({
          next: (data) => {
            this.devices = data;
          },
          error: () => {
            this.isLoading = false;
            this.errors.push('Impossible de récupérer le device');
          },
        });
    }
  }

  get numSerie() {
    return this.searchForm.get('numSerie');
  }

  reset() {
    if (this.searchForm.get('numSerie')?.value == '') {
      return;
    }
    this.searchForm.reset();
    this.paginator.pageIndex = 0;
    this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.deviceService
            .getAllDevice(this.paginator.pageIndex + 1)
            .pipe(catchError(() => observableOf(null)));
        }),
        map((response: any) => {
          this.isLoading = false;
          if (response.data === null) {
            return [];
          }
          this.resultsLength = response.data.total;
          return response.data.data;
        })
      )
      .subscribe({
        next: (data) => {
          this.devices = data;
        },
        error: () => {
          this.isLoading = false;
          this.errors.push('Impossible de récupérer les devices');
        },
      });
  }

  generatePdf() {
    this.pdfService.generatePDF().subscribe({
      next: (data) => {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        if (link.download !== undefined) {
          link.setAttribute('href', url);
          link.setAttribute(
            'download',
            `qr_code_${moment().format('DD-MM-YYYY')}`
          );
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      },
    });
  }
}
function observableOf(arg0: null): any {
  throw new Error('Function not implemented.');
}
