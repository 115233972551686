<body class="min-h-screen bg-gray-100">
  <nav class="fixed inset-x-0 max-w-full bg-white">
    <div
      class="mx-auto max-w-full border py-2 text-center lg:py-1 lg:text-center"
    >
      <div class="flex py-1">
        <h1
          class="w-full py-1 text-base font-bold leading-4 tracking-tight text-gray-900 lg:text-lg"
        >
          Connexion
        </h1>
      </div>
    </div>
  </nav>

  <div class="mx-auto max-w-md pt-20">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" #form="ngForm">
      <div class="pb-5">
        <input
          type="text"
          placeholder="Adresse email"
          formControlName="login"
          autocapitalize="none"
          class="mt-3 block h-12 w-full rounded-xl border {{
            login!.invalid && (login!.dirty || login!.touched || form.submitted)
              ? 'border-red-500'
              : 'border-gray-200'
          }} text-sm  placeholder-gray-400 transition duration-500 focus:outline-none focus:ring-2 focus:ring-gaia focus:ring-offset-1 md:text-base"
        />
        <div
          *ngIf="
            login!.invalid && (login!.dirty || login!.touched || form.submitted)
          "
          class="text-xs text-red-600"
        >
          <div *ngIf="login!.errors?.['required']">
            Veuillez saisir votre adresse mail.
          </div>
          <div *ngIf="login!.errors?.['email']">
            Veuillez saisir une adresse mail valide.
          </div>
        </div>
        <div>
          <div class="relative">
            <input
              type="password"
              [type]="passwordShow ? 'text' : 'password'"
              placeholder="Mot de passe"
              autocapitalize="none"
              formControlName="password"
              class="mt-3 block h-12 w-full rounded-xl border {{
                password!.invalid &&
                (password!.dirty || password!.touched || form.submitted)
                  ? 'border-red-500'
                  : 'border-gray-200'
              }} text-sm  placeholder-gray-400 transition duration-500 focus:outline-none focus:ring-2 focus:ring-gaia focus:ring-offset-1 md:text-base"
            />
            <div
              (click)="togglePassword()"
              class="absolute inset-y-0 right-0 flex items-center pr-5 text-sm leading-5"
            >
              <svg
                *ngIf="!passwordShow"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="text-gray-400"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.1643 12.0521C15.1643 13.7981 13.7483 15.2141 12.0023 15.2141C10.2563 15.2141 8.8403 13.7981 8.8403 12.0521C8.8403 10.3051 10.2563 8.89011 12.0023 8.89011C13.7483 8.89011 15.1643 10.3051 15.1643 12.0521Z"
                  stroke="#B8B8B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.75031 12.0521C2.75031 15.3321 6.8923 19.3541 12.0023 19.3541C17.1113 19.3541 21.2543 15.3351 21.2543 12.0521C21.2543 8.76909 17.1113 4.75009 12.0023 4.75009C6.8923 4.75009 2.75031 8.77209 2.75031 12.0521Z"
                  stroke="#B8B8B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                *ngIf="passwordShow"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6.42 17.7298C4.19 16.2698 2.75 14.0698 2.75 12.1398C2.75 8.85981 6.89 4.83981 12 4.83981C14.09 4.83981 16.03 5.50981 17.59 6.54981"
                  stroke="#B8B8B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.8497 8.6102C20.7407 9.7402 21.2597 10.9902 21.2597 12.1402C21.2597 15.4202 17.1097 19.4402 11.9997 19.4402C11.0897 19.4402 10.2007 19.3102 9.36969 19.0802"
                  stroke="#B8B8B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.76569 14.367C9.17069 13.778 8.83769 12.975 8.84069 12.138C8.83669 10.393 10.2487 8.97499 11.9947 8.97199C12.8347 8.96999 13.6407 9.30299 14.2347 9.89699"
                  stroke="#B8B8B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.1095 12.6991C14.8755 13.9911 13.8645 15.0041 12.5725 15.2411"
                  stroke="#B8B8B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.8917 4.24991L4.11771 20.0239"
                  stroke="#B8B8B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div
            *ngIf="
              password!.invalid &&
              (password!.dirty || password!.touched || form.submitted)
            "
            class="text-xs text-red-600"
          >
            <div *ngIf="password!.errors?.['required']">
              Veuillez saisir votre mot de passe.
            </div>
          </div>
        </div>
        <div *ngIf="loginErrors.length > 0" class="mt-2 text-xs text-red-600">
          <div *ngFor="let error of loginErrors">
            {{ error }}
          </div>
        </div>
        <div class="flex">
          <div class="mx-auto max-w-screen-lg grow">
            <button
              type="submit"
              [disabled]="isLoading"
              class="mt-3 flex w-full justify-center rounded-xl border border-transparent bg-gaia py-3 px-4 text-base font-medium text-white hover:bg-gaia focus:outline-none focus:ring-2 focus:ring-gaia focus:ring-offset-2"
            >
              <mat-spinner [diameter]="20" *ngIf="isLoading"></mat-spinner>
              <div *ngIf="!isLoading">Connexion</div>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div *ngIf="redirectionErrors.length > 0" class="mt-2 text-xs text-red-600">
      <div *ngFor="let error of redirectionErrors">
        {{ error }}
      </div>
    </div>
  </div>
</body>
