import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numSerie',
})
export class NumSeriePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value.includes('-')) {
      const splitted = value.split('-');
      return splitted[1];
    }

    return value;
  }
}
