<body class="min-h-screen bg-gray-100">
  <app-navbar></app-navbar>
  <div class="container mx-auto pt-5">
    <button
      routerLink="/create/"
      class="mr-2 rounded-full bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
    >
      Créer
    </button>

    <button
      type="button"
      (click)="generatePdf()"
      class="rounded-full bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
    >
      Génerer PDF
    </button>
    <form
      class="mt-5 flex max-w-xl items-center"
      [formGroup]="searchForm"
      (ngSubmit)="onSubmit()"
      #form="ngForm"
    >
      <label for="simple-search" class="sr-only">Search</label>
      <div class="relative w-full">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
        >
          <svg
            class="h-5 w-5 text-gray-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          id="simple-search"
          formControlName="numSerie"
          class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
          placeholder="Numéro de série"
          required
        />
      </div>

      <button
        type="submit"
        class="ml-2 rounded-lg border border-green-700 bg-green-700 p-2.5 text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
      >
        <svg
          class="h-5 w-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </button>

      <button
        type="button"
        (click)="reset()"
        class="ml-2 rounded-lg border border-green-700 bg-green-700 p-2.5 text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
      >
        Réinitialiser
      </button>
    </form>
    <div
      *ngIf="numSerie!.invalid && (numSerie!.dirty || numSerie!.touched)"
      class="text-xs text-red-600"
    >
      <div *ngIf="numSerie!.errors?.['required']">
        Veuillez saisir un numéro de série.
      </div>
    </div>
    <div class="mt-5 overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-left text-sm text-gray-500">
        <thead class="bg-gray-50 text-xs uppercase text-gray-700">
          <tr>
            <th scope="col" class="px-6 py-3">N° de série</th>
            <th scope="col" class="px-6 py-3">Id commonsense</th>
            <th scope="col" class="px-6 py-3">Configuré</th>
            <th scope="col" class="px-6 py-3">Réseau</th>
            <th scope="col" class="px-6 py-3">Mode Boost</th>
            <th scope="col" class="px-6 py-3">Resistance Auto</th>
            <th scope="col" class="px-6 py-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let device of devices"
            class="border-b odd:bg-white even:bg-gray-50"
          >
            <th
              scope="row"
              class="whitespace-nowrap px-6 py-4 font-medium text-gray-900"
            >
              {{ device.numSerie }}
            </th>
            <td class="px-6 py-4">
              {{
                device.commonsense_id ? device.commonsense_id : "Non attribué"
              }}
            </td>
            <td class="px-6 py-4">
              <span
                [ngClass]="{
                  'bg-red-100 text-red-800': !device.isConfigured,
                  'bg-green-100 text-green-800': device.isConfigured
                }"
                class="rounded-full px-2.5 py-0.5 text-xs font-semibold"
                >{{ device.isConfigured ? "Oui" : "Non" }}</span
              >
            </td>
            <td class="px-6 py-4">
              <span
                [ngClass]="{
                  'bg-red-100 text-red-800': device.isLora == null,
                  'bg-green-100 text-green-800': device.isLora != null
                }"
                class="rounded-full px-2.5 py-0.5 text-xs font-semibold"
                >{{
                  device.isLora == null
                    ? "Aucun"
                    : device.isLora
                    ? "LORA"
                    : "WIFI"
                }}</span
              >
            </td>
            <td class="px-6 py-4">
              <span
                [ngClass]="{
                  'bg-green-100 text-green-800': device.boostModeIsOn,
                  'bg-red-100 text-red-800': !device.boostModeIsOn
                }"
                class="rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-semibold text-red-800"
                >{{ device.boostModeIsOn ? "ON" : "OFF" }}</span
              >
            </td>
            <td class="px-6 py-4">
              <span
                [ngClass]="{
                  'bg-green-100 text-green-800': device.resistanceAutoIsOn,
                  'bg-red-100 text-red-800': !device.resistanceAutoIsOn
                }"
                class="rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-semibold text-red-800"
                >{{ device.resistanceAutoIsOn ? "ON" : "OFF" }}</span
              >
            </td>
            <td class="px-6 py-4">
              <button
                type="button"
                routerLink="/{{ device.numSerie }}/read"
                class="mr-2 mb-2 rounded-lg bg-green-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
              >
                Détails
              </button>
              <button
                type="button"
                routerLink="/{{ device.numSerie }}/edit"
                class="mr-2 mb-2 rounded-lg bg-yellow-400 px-5 py-2.5 text-sm font-medium text-white hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300"
              >
                Modifier
              </button>
              <button
                type="button"
                routerLink="/{{ device.numSerie }}/delete"
                class="mr-2 mb-2 rounded-lg bg-red-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300"
              >
                Supprimer
              </button>
              <button
                type="button"
                routerLink="/{{ device.numSerie }}/realtimedata"
                class="mr-2 mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
              >
                Dernières données en temps réel
              </button>
              <button
                type="button"
                routerLink="/{{ device.numSerie }}/data"
                class="mr-2 mb-2 rounded-lg bg-blue-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
              >
                Historique données
              </button>
            </td>
          </tr>
          <tr
            class="border-b odd:bg-white even:bg-gray-50"
            *ngIf="!isLoading && devices.length === 0"
          >
            <th
              scope="row"
              colspan="7"
              class="whitespace-nowrap px-6 py-4 text-center font-medium text-gray-900"
            >
              Aucune données trouvées
            </th>
          </tr>
          <tr class="border-b odd:bg-white even:bg-gray-50" *ngIf="isLoading">
            <th
              scope="row"
              colspan="7"
              class="whitespace-nowrap px-6 py-4 text-center font-medium text-gray-900"
            >
              <div class="flex justify-center">
                <mat-spinner [diameter]="20"></mat-spinner>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>

    <mat-paginator
      class="mt-2 mb-5 rounded-lg bg-gray-50 shadow-md"
      [length]="resultsLength"
      [pageSize]="10"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</body>
