import { NavigationService } from './../../services/navigation/navigation.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SocketService } from './../../services/socket/socket.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import moment from 'moment-timezone';

@Component({
  selector: 'app-real-time-data',
  templateUrl: './real-time-data.component.html',
  styleUrls: ['./real-time-data.component.css'],
})
export class RealTimeDataComponent implements OnInit, OnDestroy {
  numSerie!: string;
  isLoading: boolean = true;

  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  yAxisLabel: string = 'Consommation';
  timeline: boolean = true;
  colorScheme: any = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };
  data: any = [
    {
      name: "Consommation d'électricité",
      series: [],
    },
    {
      name: "Consommation d'eau",
      series: [],
    },
    {
      name: 'Température 1',
      series: [],
    },
    {
      name: 'Température 2',
      series: [],
    },
    {
      name: 'Température 3',
      series: [],
    },
    {
      name: 'State',
      series: [],
    },
  ];
  view: any = [1000, 300];

  constructor(
    private SocketService: SocketService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private navigationService: NavigationService
  ) {
    this.numSerie = this.route.snapshot.params['numSerie'];
    this.SocketService.connect();
    this.SocketService.sendNumSerie(this.numSerie);
  }
  ngOnDestroy(): void {
    this.SocketService.disconnect();
  }

  ngOnInit(): void {
    this.SocketService.onDataReceive().subscribe({
      next: (data: any) => {
        this.isLoading = false;
        for (let index = 0; index <= 5; index++) {
          this.data[index].series.push(
            ...data[index].map((data: any) => {
              return {
                value: data.value,
                name: new Date(data.name),
              };
            })
          );
        }
        this.data = [...this.data];
      },
      error: (e) => {
        this.isLoading = false;
        console.log(e);
      },
    });
    this.SocketService.onConnectError().subscribe({
      next: (data: any) => {
        // refresh token
        const refresh = localStorage.getItem('atelier_refresh');
        if (!refresh) {
          this.authService.logout();
          this.router.navigateByUrl('/connexion', {
            state: { sessionExpire: true },
          });
          return;
        }
        this.authService.refreshToken(refresh).subscribe({
          next: (response: any) => {
            localStorage.setItem('atelier_token', response.data.token);
            this.SocketService.connect();
            this.SocketService.sendNumSerie(this.numSerie);
          },
          error: (e: any) => {
            this.authService.logout();
            this.router.navigateByUrl('/connexion', {
              state: { sessionExpire: true },
            });
          },
        });
      },
    });

    this.SocketService.onJwtExpire().subscribe({
      next: (response: any) => {
        this.SocketService.disconnect();
        const refresh = localStorage.getItem('atelier_refresh');
        if (!refresh) {
          this.authService.logout();
          this.router.navigateByUrl('/connexion', {
            state: { sessionExpire: true },
          });
          return;
        }
        this.authService.refreshToken(refresh).subscribe({
          next: (response: any) => {
            localStorage.setItem('atelier_token', response.data.token);
            this.SocketService.connect();
            this.SocketService.sendNumSerie(this.numSerie);
          },
          error: (e: any) => {
            this.authService.logout();
            this.router.navigateByUrl('/connexion', {
              state: { sessionExpire: true },
            });
          },
        });
      },
    });
  }

  back() {
    this.navigationService.back();
  }
}
