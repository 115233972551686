import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket!: io.Socket;
  constructor() {}
  onDataReceive() {
    return new Observable((observer) => {
      this.socket.on('data', (msg) => {
        observer.next(msg);
      });
    });
  }

  sendNumSerie(numSerie: string) {
    this.socket.emit('numSerie', numSerie);
  }

  onConnectError() {
    return new Observable((observer) => {
      this.socket.on('connect_error', (msg) => {
        observer.next(msg);
      });
    });
  }
  disconnect() {
    this.socket.disconnect();
  }
  connect() {
    this.socket = io.connect(environment.apiUrl, {
      query: {
        token: localStorage.getItem('atelier_token'),
      },
    });
  }

  onJwtExpire() {
    return new Observable((observer) => {
      this.socket.on('jwtExpire', (msg) => {
        observer.next(msg);
      });
    });
  }
}
