import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  isLoading: boolean = false;
  loginErrors: Array<string> = [];
  redirectionErrors: Array<string> = [];
  passwordShow: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    const state: any = this.location.getState();

    if (state && state.sessionExpire) {
      this.redirectionErrors.push('Votre session a expiré.');
    }

    this.loginForm = this.formBuilder.group(
      {
        login: [
          '',
          [Validators.required, Validators.email, Validators.maxLength(255)],
        ],
        password: new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change',
        }),
      },
      { updateOn: 'blur' }
    );
  }
  onSubmit() {
    if (this.loginForm.valid) {
      this.loginErrors = [];
      this.redirectionErrors = [];
      this.isLoading = true;
      const login = this.loginForm.get('login')?.value;
      const password = this.loginForm.get('password')?.value;

      this.authService.login(login, password).subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.authService.user.next(response.data.user);
          localStorage.setItem('atelier_token', response.data.token);
          localStorage.setItem('atelier_refresh', response.data.refreshToken);
        },
        error: (e: any) => {
          this.isLoading = false;
        },
        complete: () => {
          this.router.navigateByUrl('/');
        },
      });
    }
  }
  get login() {
    return this.loginForm.get('login');
  }

  get password() {
    return this.loginForm.get('password');
  }
  togglePassword() {
    this.passwordShow = !this.passwordShow;
  }
}
