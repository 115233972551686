<body class="min-h-screen bg-gray-100">
  <app-navbar></app-navbar>
  <div class="container mx-auto pt-5">
    <button
      (click)="back()"
      class="rounded-full bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
    >
      Retour
    </button>
    <div
      class="mt-5 flex flex-col items-center justify-center gap-2 md:flex-row"
    >
      <div>
        <form [formGroup]="readForm" class="grow">
          <div class="mb-3">
            <div class="flex gap-2">
              <div class="grow">
                <label
                  for="numSerie"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >N° de série</label
                >
                <input
                  type="text"
                  formControlName="numSerie"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div>
                <label
                  for="commonsense_id"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Id CommonSense</label
                >
                <input
                  type="text"
                  formControlName="commonsense_id"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label
              for="isLora"
              class="mb-2 block text-sm font-medium text-gray-900"
              >Type de transmission</label
            >
            <input
              type="text"
              formControlName="isLora"
              [readonly]="true"
              class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
            />
          </div>
          <div class="mb-3">
            <div class="flex gap-2">
              <div class="grow">
                <label
                  for="localisation"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Localisation</label
                >
                <input
                  type="text"
                  formControlName="localisation"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div>
                <label
                  for="capacite"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Capacité</label
                >
                <input
                  type="text"
                  formControlName="capacite"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div>
                <label
                  for="impulsionParLitre"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Impulsion par litre</label
                >
                <input
                  type="text"
                  formControlName="impulsionParLitre"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div>
                <label
                  for="puissance"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Puissance</label
                >
                <input
                  type="text"
                  formControlName="puissance"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label
              for="modele"
              class="mb-2 block text-sm font-medium text-gray-900"
              >Modèle</label
            >
            <input
              type="text"
              formControlName="modele"
              [readonly]="true"
              class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
            />
          </div>
          <div class="mb-3">
            <label
              for="isConfigured"
              class="mb-2 block text-sm font-medium text-gray-900"
              >Configuration</label
            >
            <input
              type="text"
              formControlName="isConfigured"
              [readonly]="true"
              class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
            />
          </div>
          <div class="mb-3">
            <div class="flex gap-2">
              <div>
                <label
                  for="boostModeIsOn"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Mode Boost</label
                >
                <input
                  type="text"
                  formControlName="boostModeIsOn"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div class="grow">
                <label
                  for="boostModeActivationDate"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Date activation mode boost</label
                >
                <input
                  type="text"
                  formControlName="boostModeActivationDate"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="flex gap-2">
              <div class="grow">
                <label
                  for="resistanceAutoIsOn"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Résistance Auto</label
                >
                <input
                  type="text"
                  formControlName="resistanceAutoIsOn"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>

              <div class="grow">
                <label
                  for="tempSouhaitee"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Températeure résistance</label
                >
                <input
                  type="text"
                  formControlName="tempSouhaitee"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="flex gap-2">
              <div class="grow">
                <label
                  for="nbDoucheMatin"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Nb Douche resistance matin</label
                >
                <input
                  type="text"
                  formControlName="nbDoucheMatin"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div class="grow">
                <label
                  for="nbDoucheMatin"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Heure resistance matin</label
                >
                <input
                  type="text"
                  formControlName="timeMatin"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div class="grow">
                <label
                  for="nbDoucheSoir"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Nb Douche resistance soir</label
                >
                <input
                  type="text"
                  formControlName="nbDoucheSoir"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div class="grow">
                <label
                  for="nbDoucheSoir"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Heure resistance soir</label
                >
                <input
                  type="text"
                  formControlName="timeSoir"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="flex gap-2">
              <div class="grow">
                <label
                  for="createdAt"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Crée le</label
                >
                <input
                  type="text"
                  formControlName="createdAt"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div class="grow">
                <label
                  for="updatedAt"
                  class="mb-2 block text-sm font-medium text-gray-900"
                  >Modifiée le</label
                >
                <input
                  type="text"
                  formControlName="updatedAt"
                  [readonly]="true"
                  class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-green-500 focus:ring-green-500"
                />
              </div>
            </div>
          </div>
        </form>
        <div class="flex flex-wrap">
          <a
            routerLink="/{{ numSerieRouteParams }}/edit"
            class="mr-2 mb-2 rounded-full bg-yellow-400 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 dark:focus:ring-yellow-900"
          >
            Modifier
          </a>
          <a
            routerLink="/{{ numSerieRouteParams }}/delete"
            class="mr-2 mb-2 rounded-full bg-red-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300"
          >
            Supprimer
          </a>
          <a
            routerLink="/{{ numSerieRouteParams }}/realtimedata"
            class="mr-2 mb-2 rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
          >
            Données en temp réel
          </a>
          <a
            routerLink="/{{ numSerieRouteParams }}/data"
            class="mr-2 mb-2 rounded-full bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300"
          >
            Historique des données
          </a>
        </div>
      </div>
      <div class="flex flex-col items-center text-center">
        <div #qrcode class="p-3">
          <qr-code
            *ngIf="numSerie!.value"
            #parent
            [value]="numSerie!.value"
            [size]="350"
            level="H"
          ></qr-code>
          <span>{{ numSerie!.value | numSerie }}</span>
        </div>
        <div class="flex gap-2">
          <button
            *ngIf="numSerie!.value"
            (click)="saveAsImage()"
            class="mt-2 rounded-lg bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 sm:w-auto"
          >
            Télecharger image
          </button>
          <button
            *ngIf="numSerie!.value"
            (click)="generatePDF()"
            class="mt-2 rounded-lg bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 sm:w-auto"
          >
            Télecharger PDF
          </button>
        </div>
      </div>
    </div>
  </div>
</body>
