import { NavigationService } from './../../services/navigation/navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DeviceService } from 'src/app/services/device/device.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
moment.locale('fr');
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css'],
})
export class DeleteComponent implements OnInit {
  @ViewChild('parent') parent: any;
  @ViewChild('qrcode') invoiceElement!: ElementRef;

  isLoading: boolean = true;
  numSerieRouteParams!: string;
  device!: any;
  deleteForm!: FormGroup;

  constructor(
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private navigationService: NavigationService
  ) {
    this.numSerieRouteParams = this.route.snapshot.params['numSerie'];
  }

  ngOnInit(): void {
    this.deleteForm = this.formBuilder.group({
      numSerie: [''],
      commonsense_id: [''],
      localisation: [''],
      capacite: [''],
      impulsionParLitre: [''],
      puissance: [''],
      modele: [''],
      resistanceAutoIsOn: [''],
      boostModeIsOn: [''],
      nbDoucheMatin: [''],
      timeMatin: [''],
      nbDoucheSoir: [''],
      timeSoir: [''],
      tempSouhaitee: [''],
      boostModeActivationDate: [''],
      createdAt: [''],
      updatedAt: [''],
      isConfigured: [''],
      isLora: [''],
    });
    this.deviceService.getDeviceByNumSerie(this.numSerieRouteParams).subscribe({
      next: (data: any) => {
        this.isLoading = false;
        if (!data.data) {
          this.router.navigateByUrl('/');
          return;
        }
        this.device = data.data;
        this.deleteForm.patchValue({
          numSerie: this.device.numSerie,
          commonsense_id: this.device.commonsense_id,
          localisation: this.device.localisation,
          capacite: this.device.capacite,
          impulsionParLitre: this.device.impulsionParLitre,
          puissance: this.device.puissance,
          modele: this.device.modele,
          resistanceAutoIsOn: this.device.resistanceAutoIsOn ? 'ON' : 'OFF',
          boostModeIsOn: this.device.boostModeIsOn ? 'ON' : 'OFF',
          nbDoucheMatin: this.device.nbDoucheMatin,
          timeMatin: this.device.timeMatin,
          nbDoucheSoir: this.device.nbDoucheSoir,
          timeSoir: this.device.timeSoir,
          isConfigured: this.device.isConfigured
            ? 'Configuré'
            : 'Non configuré',
          tempSouhaitee: this.device.tempSouhaitee,
          boostModeActivationDate: this.device.boostModeActivationDate,
          createdAt: moment(this.device.createdAt).format('LLLL'),
          updatedAt: moment(this.device.updatedAt).format('LLLL'),
          isLora:
            this.device.isLora == null
              ? 'Aucun'
              : this.device.isLora
              ? 'LORA'
              : 'WIFI',
        });
      },
      error: (e) => {
        this.isLoading = false;
        console.log(e);
      },
      complete: () => {},
    });
  }

  delete() {
    this.isLoading = true;
    this.deviceService
      .deleteDeviceByNumSerie(this.numSerieRouteParams)
      .subscribe({
        complete: () => {
          this.isLoading = false;
          this.router.navigateByUrl('/');
        },
        error: (e) => {
          this.isLoading = false;
          console.log(e);
        },
      });
  }

  public generatePDF(): void {
    html2canvas(this.invoiceElement.nativeElement, { scale: 3 }).then(
      (canvas) => {
        const imageGeneratedFromTemplate = canvas.toDataURL('image/png');
        const fileWidth = 200;
        const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
        let PDF = new jsPDF('p', 'mm', 'a4');
        PDF.addImage(
          imageGeneratedFromTemplate,
          'PNG',
          0,
          5,
          fileWidth,
          generatedImageHeight
        );
        PDF.html(this.invoiceElement.nativeElement.innerHTML);
        PDF.save(`qr_code_${this.device.numSerie}.pdf`);
      }
    );
  }

  onSubmit() {
    this.delete();
  }

  get numSerie() {
    return this.deleteForm.get('numSerie');
  }

  saveAsImage() {
    const parentElement =
      this.parent.elementRef.nativeElement.querySelector('img').src;
    let blobData = this.convertBase64ToBlob(parentElement);
    const blob = new Blob([blobData], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download =
      'qr_code_' +
      this.deleteForm
        .get('numSerie')!
        .value.replace(/[^a-z0-9]/gi, '_')
        .toLowerCase();
    link.click();
  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }

  back() {
    this.navigationService.back();
  }
}
