import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationService } from './../../services/navigation/navigation.service';
import { DeviceService } from './../../services/device/device.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment-timezone';
import { dateRangeValidator } from 'src/app/validators/dateRange.validator';
import { Unit } from 'src/app/interface/unit';

moment.tz.setDefault('Indian/Reunion');

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css'],
})
export class DataComponent implements OnInit {
  numSerie!: string;
  isLoading: boolean = true;

  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  yAxisLabel: string = 'Consommation';
  timeline: boolean = true;
  colorScheme: any = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5'],
  };
  data: any = [
    {
      name: "Consommation d'électricité",
      series: [],
    },
    {
      name: "Consommation d'eau",
      series: [],
    },
    {
      name: 'Température 1',
      series: [],
    },
    {
      name: 'Température 2',
      series: [],
    },
    {
      name: 'Température 3',
      series: [],
    },
    {
      name: 'State',
      series: [],
    },
  ];
  dataForm!: FormGroup;
  granularity: string = 'minute';
  constructor(
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private formBuilder: FormBuilder
  ) {
    this.numSerie = this.route.snapshot.params['numSerie'];
    this.dataForm = this.formBuilder.group(
      {
        dateStart: [
          moment().utc(true).subtract(1, 'week').startOf('minute'),
          Validators.required,
        ],
        dateStop: [moment().utc(true).startOf('minute'), Validators.required],
      },
      {
        validators: dateRangeValidator(),
      }
    );
  }

  ngOnInit(): void {
    this.refresh();
  }

  back() {
    this.navigationService.back();
  }

  refresh() {
    if (this.dataForm.valid) {
      this.deviceService
        .getDataByNumSerie(
          this.numSerie,
          this.granularity,
          this.dataForm.get('dateStart')?.value,
          this.dataForm.get('dateStop')?.value
        )
        .subscribe({
          next: (data: any) => {
            this.data[0].series = data.data.count1.series;
            this.data[1].series = data.data.count2.series;
            this.data[2].series = data.data.temp1.series;
            this.data[3].series = data.data.temp2.series;
            this.data[4].series = data.data.temp3.series;
            this.data[5].series = data.data.state.series;
            this.isLoading = false;
            this.data.map((data: any) => {
              data.series = data.series.map((serie: any) => {
                return {
                  value: serie.value,
                  name: new Date(serie.name),
                };
              });
            });
            this.data = [...this.data];
          },
          error: (e) => {
            this.isLoading = false;
            console.log(e);
          },
        });
    }
  }

  get dateStart() {
    return this.dataForm.get('dateStart');
  }

  get dateStop() {
    return this.dataForm.get('dateStop');
  }

  setGranularity(granularity: string) {
    this.granularity = granularity;
    this.refresh();
  }
  onDateChange() {
    if (this.dataForm.valid) {
      this.refresh();
    }
  }
}
