<body class="min-h-screen bg-gray-100">
  <app-navbar></app-navbar>
  <div class="container mx-auto pt-5">
    <button
      (click)="back()"
      class="rounded-full bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300"
    >
      Retour
    </button>
    <div class="mt-5 flex w-full flex-col items-center">
      <ngx-charts-line-chart
        [view]="view"
        [scheme]="colorScheme"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [timeline]="true"
        [results]="data"
        [animations]="true"
        [legend]="true"
        [showGridLines]="true"
        [roundDomains]="true"
        legendTitle="Légende"
      >
      </ngx-charts-line-chart>
    </div>
  </div>
</body>
