import { NavigationService } from './../../services/navigation/navigation.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from 'src/app/services/device/device.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css'],
})
export class EditComponent implements OnInit {
  @ViewChild('parent') parent: any;
  @ViewChild('qrcode') invoiceElement!: ElementRef;

  isLoading: boolean = true;
  numSerieRouteParams!: string;
  device!: any;
  editForm!: FormGroup;

  constructor(
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private navigationService: NavigationService
  ) {
    this.numSerieRouteParams = this.route.snapshot.params['numSerie'];
  }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      localisation: [''],
      capacite: [''],
      impulsionParLitre: [''],
      puissance: [''],
      modele: [''],
    });
    this.deviceService.getDeviceByNumSerie(this.numSerieRouteParams).subscribe({
      next: (data: any) => {
        this.isLoading = false;
        if (!data.data) {
          this.router.navigateByUrl('/');
          return;
        }
        this.device = data.data;
        this.editForm.patchValue({
          localisation: this.device.localisation,
          capacite: this.device.capacite,
          impulsionParLitre: this.device.impulsionParLitre,
          puissance: this.device.puissance,
          modele: this.device.modele,
        });
      },
      error: (e) => {
        this.isLoading = false;
        console.log(e);
      },
      complete: () => {},
    });
  }
  public generatePDF(): void {
    html2canvas(this.invoiceElement.nativeElement, { scale: 3 }).then(
      (canvas) => {
        const imageGeneratedFromTemplate = canvas.toDataURL('image/png');
        const fileWidth = 200;
        const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
        let PDF = new jsPDF('p', 'mm', 'a4');
        PDF.addImage(
          imageGeneratedFromTemplate,
          'PNG',
          0,
          5,
          fileWidth,
          generatedImageHeight
        );
        PDF.html(this.invoiceElement.nativeElement.innerHTML);
        PDF.save(`qr_code_${this.device.numSerie}.pdf`);
      }
    );
  }
  update() {
    this.isLoading = true;
    this.deviceService
      .updateDeviceByNumSerie(this.numSerieRouteParams, this.editForm.value)
      .subscribe({
        next: (data: any) => {
          console.log(data);
        },
        complete: () => {
          this.isLoading = false;
        },
        error: (e) => {
          this.isLoading = false;
          console.log(e);
        },
      });
  }

  onSubmit() {
    if (this.editForm.valid) {
      this.update();
    }
  }

  get numSerie() {
    return this.editForm.get('numSerie');
  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }

  saveAsImage() {
    const parentElement =
      this.parent.elementRef.nativeElement.querySelector('img').src;
    let blobData = this.convertBase64ToBlob(parentElement);
    const blob = new Blob([blobData], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download =
      'qr_code_' +
      this.editForm
        .get('numSerie')!
        .value.replace(/[^a-z0-9]/gi, '_')
        .toLowerCase();
    link.click();
  }

  back() {
    this.navigationService.back();
  }
}
