import { Router } from '@angular/router';
import { DeviceService } from 'src/app/services/device/device.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent implements OnInit {
  @ViewChild('parent') parent: any;
  @ViewChild('qrcode') invoiceElement!: ElementRef;
  createForm!: FormGroup;
  isLoading: boolean = true;

  constructor(
    private deviceService: DeviceService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.createForm = this.formBuilder.group({
      numSerie: ['', Validators.required],
    });
  }

  create() {
    this.deviceService.addDevice(this.createForm.value).subscribe({
      complete: () => {
        this.isLoading = false;
        this.router.navigateByUrl('/');
      },
      error: (e) => {
        this.isLoading = false;
        console.log(e);
      },
    });
  }
  onSubmit() {
    if (this.createForm.valid) {
      this.create();
    }
  }
  saveAsImage() {
    const parentElement =
      this.parent.elementRef.nativeElement.querySelector('img').src;
    let blobData = this.convertBase64ToBlob(parentElement);
    const blob = new Blob([blobData], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = this.createForm
      .get('numSerie')!
      .value.replace(/[^a-z0-9]/gi, '_')
      .toLowerCase();
    link.click();
  }
  public generatePDF(): void {
    html2canvas(this.invoiceElement.nativeElement, { scale: 3 }).then(
      (canvas) => {
        const imageGeneratedFromTemplate = canvas.toDataURL('image/png');
        const fileWidth = 100;
        const generatedImageHeight = 100;
        let PDF = new jsPDF('p', 'mm', 'a4');

        const pageWidth = PDF.internal.pageSize.getWidth();
        const pageHeight = PDF.internal.pageSize.getHeight();

        PDF.addImage(
          imageGeneratedFromTemplate,
          'PNG',
          pageWidth / 2 - fileWidth / 2,
          pageHeight / 2 - generatedImageHeight / 2,
          fileWidth,
          generatedImageHeight
        );
        PDF.html(this.invoiceElement.nativeElement.innerHTML);
        PDF.save('angular-invoice-pdf-demo.pdf');
      }
    );
  }

  get numSerie() {
    return this.createForm.get('numSerie');
  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }
}
